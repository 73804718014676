import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { isProduction } from '@env/environment';
import { ModalType, RegistrationAdditionalData, SelectItem } from '@models';
import { ModalService } from '@services';
import { DataAddressComponent } from '@sharedcomponents/data-address/data-address.component';
import { AppConstants } from '@utils/app-constants';
import { CreditCardUtil } from '@utils/credit-card-util';
import { DateUtil } from '@utils/date-util';
import { FormUtil } from '@utils/form-util';


@Component({
  selector: 'app-form-additional-data',
  templateUrl: './form-additional-data.component.html',
  styleUrls: ['./form-additional-data.component.scss'],
})
export class FormAdditionalDataComponent  implements OnInit, AfterViewInit, OnDestroy {

  readonly MASTERCARD_ID = 2;
  readonly docMask = AppConstants.Mask.doc;

  @ViewChild('formAddress', { static: false }) formAddress: DataAddressComponent;
  @ViewChild('formAdditional', { static: false }) form: NgForm;

  @Input() showAddress = true;
  @Input() adhesionUrl = '';

  @Input() set flagCardOptions(newFlagCardOptionsValue: SelectItem<any>[]) {
    this._flagCardOptions = newFlagCardOptionsValue;
    this.selectPaymentFlag(this.formAdditionalData.payment.cardFlag);
  }

  get flagCardOptions(): SelectItem<any>[] {
    return this._flagCardOptions;
  }

  parcelOptions: any[];

  @Input() formAdditionalData: RegistrationAdditionalData;

  @Output() parcel = new EventEmitter<number>();

  accept = false;

  listMounths: SelectItem<any>[];
  listYears: SelectItem<any>[];
  private _flagCardOptions: SelectItem<any>[];

  constructor(
    private readonly modalService: ModalService,
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.form.form.setValidators(CreditCardUtil.isExpired);
    }, 0);
  }

  ngOnDestroy() {
    this.form.form.setValidators(null);
  }

  ngOnInit() {
    this.listMounths = DateUtil.getMonthsOfTheYear();
    this.listYears = DateUtil.getYears();
  }

  init() {
  }

  isValid(): boolean {
    FormUtil.touchForm(this.form.control);
    return (!this.formAddress || this.formAddress.isValid()) && this.form.valid && this.accept;
  }

  selectPaymentFlag(flag: number) {
    if (this.flagCardOptions) {
      const options = (this.flagCardOptions.find(option => option.id === flag) || {}).parcels;

      if (options) {
        this.parcelOptions = [...options];
        //  Necessário atualizar para 0, para alterar o combo das parcelas.
        this.formAdditionalData.payment.parcels = 0;
        setTimeout(() => {
          this.formAdditionalData.payment.parcels = this.parcelOptions[this.parcelOptions.length - 1].id;
          this.parcelChange(this.formAdditionalData.payment.parcels);
        }, 0);
      }
    }
  }

  parcelChange(parcelCode: number) {
    if (parcelCode) {
      this.parcel.emit(this.parcelOptions.find(p => p.id === parcelCode).parcel);
    }
  }

  openCVVModal() {
    this.modalService.show({
      type: ModalType.creditCardInfo,
      title: null,
      message: null,
      data: {},
    });
  }

  @HostListener('keydown', ['$event'])
  autoFill(event: KeyboardEvent) {
    if (!isProduction && event.key === '/' && event.ctrlKey) {

      Object.assign(this.formAdditionalData, {
        address: {
          zipCode: '13567-000',
          street: 'Fool Street',
          number: '1',
          adjunct: null,
          neighborhood: 'Centro',
          city: { id: 0 },
          publicPlace: { id: 2 },
        },
        payment: {
          coupon: '',
          discount: 0,
          cardOwnerName: 'TokenLab',
          cardNumber: '1234.1234.1234.1234',
          cardCVV: '111',
          cardFlag: '1',
          cardExpiringMonth: '10',
          cardExpiringYear: '2020',
          parcels: 2,
        },
      });
      this.isValid();
    }
  }

}
