// import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '@services';

import { Brand, BrandService } from '@services/brand.service';

@Component({
  templateUrl: './privacy-center.component.html',
  styleUrls: ['./privacy-center.component.scss'],
})
export class PrivacyCenterComponent implements OnInit, AfterViewInit {
  imageHeader: string;
  headerTitle: string;

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  brand: Brand;

  privacyStorageItems: { image: string; text: string }[] = [
    {
      image: '/assets/icons_custom/cones-ic-privacy-privacy-2.svg',
      text: 'Usamos meios razoáveis para garantir a segurança dos dados e assim prevenir incidentes que possam colocar em risco sua privacidade. Anonimizamos seus dados toda vez que é necessário garantir a sua privacidade ou quando é exigido pela legislação.',
    },
    {
      image: '/assets/icons_custom/cones-ic-privacy-storage-2.svg',
      text: 'Armazenamos as informações apenas em servidores próprios ou contratados por nós e que têm a nossa total confiança.',
    },
    {
      image: '/assets/icons_custom/cones-ic-privacy-document-v-2.svg',
      text: 'Contamos com uma consultoria especializada que nos orienta a respeito do que exige a Lei Geral de Proteção de Dados Pessoais.',
    },
  ];

  questions: { image: string; text: string }[] = [
    {
      image: '/assets/icons_custom/cones-ic-privacy-sac-v-2.svg',
      text: 'Se você tem alguma questão que ainda gostaria de tratar sobre privacidade, envie uma mensagem para nossa Encarregada pelo Tratamento de Dados Pessoais (DPO) Gabriella Somaglino, através do e-mail <a class="text-underline" href="mailto:dpo@bodytech.com.br">dpo@bodytech.com.br</a>.',
    },
  ];

  questionsFr: { image: string; text: string }[] = [
    {
      image: '/assets/icons_custom/cones-ic-privacy-sac-v-2.svg',
      text: 'Se você tem alguma questão que ainda gostaria de tratar sobre privacidade, envie uma mensagem para nossa Encarregada pelo Tratamento de Dados Pessoais (DPO) Gabriella Somaglino, através do e-mail <a class="text-underline" href="mailto:dpo@formulaacademia.com.br">dpo@formulaacademia.com.br</a>.',
    },
  ];

  constructor(
    public readonly brandService: BrandService,
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute
  ) {
    this.brand = this.brandService.getBrand();
  }

  ngOnInit() {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
