import { AgendaAgeRange, EvoBaseParamsModel } from '@models';


export class AppConstants {
  public static HTTP_UNKNOWN = 0;
  public static HTTP_OK = 200;
  public static HTTP_BAD_REQUEST = 400;
  public static HTTP_UNAUTHORIZED = 401;
  public static HTTP_FORBIDDEN = 403;
  public static HTTP_NOT_ACCEPTABLE = 406;
  public static HTTP_SERVER_ERROR = 500;

  public static LOCALE = 'pt-BR';

  public static DEVICE = 'PORTAL-WEB';
  public static GYM_UNIT_ID: keyof Pick<EvoBaseParamsModel, 'gymUnitId'> = 'gymUnitId';
  public static STOR_AGENDA_GYM = 'agenda-gym-selected';
  public static STOR_KIDS_GYM = 'kids-gym-selected';
  public static STOR_KIDS_GYM_ID = 'kids-gym-id-selected';
  public static STOR_APP_USER_REGISTRATION = 'app-user-registration';
  public static STOR_LAST_LOGIN = 'user-login';
  public static STOR_LOGGED = 'user-is-logged';
  public static STOR_RESET_USER_ACESS_TOKEN = 'reset-user-access-token';
  public static STOR_SUR_TOKEN = 'ultra-application-token';
  public static STOR_USER = 'user-data';
  public static STOR_REGISTRATION_LANDING_PAGE_KIDS = 'registration-landing-page-kids';
  public static STOR_MAIN_GYM_ID = 'main-gym-id';

  public static STOR_APP_USER_TOKEN = 'app-user-token';
  public static STOR_COGNITO_TOKEN = 'cognito-token';
  public static STOR_DOCUSIGN_TOKEN = 'docmod-token';

  public static SUR_ERROR = {
    INVALID_APP_TOKEN: 'br.com.bodytech.portal.token.unauthorized',
  };

  public static DOCU_SIGN_MICROSERVICE_ERROR = {
    API_INVALID_OR_EXPIRED_TOKEN: 6
  };

  public static LEGACY_ERROR = {
    INVALID_USER_TOKEN: '4030002',
    INVALID_LOGIN_OR_PASSWORD: '4030003',
    EXISTING_USER: '4120012',
    BLOCKED_USER: '4030004',
    DATA_NOT_FOUND: '4120001',
    CREDIT_CARD_NOT_FOUND:  '4120036',
    UPDATE_CREDIT_CARD_ERROR: '4120001',
    EXISTING_CPF: '4120017',
  };

  public static PARAM_SEARCHGYM_ACTIVITIES = 'a';
  public static PARAM_SEARCHGYM_SERVICES = 's';

  public static PARAM_AGENDA_ACTIVITIES = 'a';
  public static PARAM_AGENDA_AGE_RANGES = 'i';

  public static PAYMENT_CREDIT_CARD_ID = 1;

  public static readonly googleMapUrl = 'https://www.google.com.br/maps/dir/My+Location/';

  public static readonly Mask = {
    date: {
      mask: Date,
      pattern: '00/00/00000',
      min: new Date(1900, 0, 1),
    },
    zip: { mask: '00.000-000' },
    doc: { mask: '000.000.000-00' },
    phone: { mask: [{mask: '(00) 0000-0000'}, {mask: '(00) 00000-0000'}] },
    expire: (raw: string) => {
      const pattern = [/[0-1]/, /[1-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
      const rawArray = Array.from(raw);

      if (rawArray[0] && rawArray[0] === '1') {
        pattern[1] = /[0-2]/;
      }

      if (rawArray[3] && rawArray[3] === '1') {
        pattern[4] = /[9]/;
      }

      return pattern;
    },
    cnpj: { mask: '00.000.000/0000-00' },
    dateFull: (raw: string) => {
      const pattern = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
      const rawArray = Array.from(raw);

      if (rawArray[2] && rawArray[2] !== '/') {
        rawArray.splice(2, 0, '/');
      }
      if (rawArray[5] && rawArray[5] !== '/') {
        rawArray.splice(5, 0, '/');
      }

      if (rawArray[0] && rawArray[0] === '3') {
        pattern[1] = /[0-1]/;
      } else if (rawArray[0] && rawArray[0] === '0') {
        pattern[1] = /[1-9]/;
      }

      if (rawArray[3] && rawArray[3] === '1') {
        pattern[4] = /[0-2]/;
      } else if (rawArray[3] && rawArray[3] === '0') {
        pattern[4] = /[1-9]/;
      }

      if (rawArray[6] && rawArray[6] === '1') {
        pattern[7] = /[9]/;
      }

      return pattern;
    },
  };

  // tslint:disable-next-line:max-line-length
  public static EMAIL_REGEXP = '[a-zA-Z0-9\+\\.\_\%\-]{1,256}\@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+';

  public static REGISTRATION_LENGTH = 6;

  // Idades específicas para o Filtro das atividades Baby & Kids
  // To do - Criar um serviço no back para trazer essas informações
  public static KIDS_AGE_RANGE: AgendaAgeRange[] = [
    {
      id: 1,
      maximumAge: 1,
      minimumAge: 0.5,
      name: 'Bebê I',
    },
    {
      id: 2,
      maximumAge: 2,
      minimumAge: 2,
      name: 'Bebê II',
    },
    {
      id: 3,
      maximumAge: 4,
      minimumAge: 3,
      name: 'Kids I',
    },
    {
      id: 4,
      maximumAge: 6,
      minimumAge: 5,
      name: 'Kids II',
    },
    {
      id: 5,
      maximumAge: 8,
      minimumAge: 7,
      name: 'Kids III',
    },
    {
      id: 6,
      maximumAge: 11,
      minimumAge: 9,
      name: 'Kids IV',
    },
  ];
}
